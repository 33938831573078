import React from "react";
import { Chandlier } from "./Chandlier.js";

export const Looster = ({ pos, right, isOn }) => {
  return (
    <div
      className="looster"
      style={{
        transform: `translateX(${pos * 3}px)`,
        right: right,
      }}
    >
      <Chandlier isOn={isOn} />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  if(prevProps.isOn !== nextProps.isOn) {
    return false
  }
  if(prevProps.pos === nextProps.pos) {
    return true
  }
  return false
}

export default React.memo(Looster, areEqual);