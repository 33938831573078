import React, { useEffect, useRef } from "react";
import audio from "./assets/1.mp3";

let audioo = null;
export const MoveAudioPlayer = ({ isMoving }) => {
  useEffect(() => {
    if (!audioo) {
      audioo = new Audio(audio);
      audioo.loop = true;
    }
    if (isMoving) {
      audioo.currentTime = 0
      audioo.play();
    } else {
      audioo.pause();
    }
  }, [isMoving]);

  return null;
};
