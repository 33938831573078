import React, { useEffect, useRef, useState } from "react";
import moreInfoIcon from "./assets/moreInfo.svg";

export const Project = ({
  onClick,
  isOn,
  index,
  firstPos,
  dimension,
  image,
  pos,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const projectRef = useRef();
  useEffect(() => {
    const diff = Math.abs(
      (projectRef.current.getBoundingClientRect().right +
        projectRef.current.getBoundingClientRect().left) /
        2 -
        window.innerWidth / 2
    );
    if (diff < 200) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }, [pos]);
  return (
    <div
      onClick={onClick}
      className={`board ${isOn ? "shadow" : ""}`}
      style={{
        transform: `translateX(${pos}px)`,
        right: firstPos,
        background: `url(${image})`,
        height: dimension,
        width: dimension,
      }}
      ref={projectRef}
    >
      <div className={`open-info ${showInfo ? "show-info" : ""}`}>
        <img alt="show-info" width="54px" src={moreInfoIcon} />
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  if(prevProps.isOn !== nextProps.isOn) {
    return false
  }
  if(prevProps.pos === nextProps.pos) {
    return true
  }
  return false;
}

export default React.memo(Project, areEqual);
