import React, { Component } from "react";
import lottie from "lottie-web";
import ReactGA from "react-ga";

import * as animationData from "./assets/man.json";
import RightIcon from "./assets/right2.svg";
import PauseIcon from "./assets/pause2.svg";

import MightyBoard from "./assets/Mighty.svg";
import SpationBoard from "./assets/Spation.svg";
import PingonioBoard from "./assets/Pingonio.svg";
import ObourBoard from "./assets/Obour.svg";
import CoowntBoard from "./assets/Coownt.svg";
import XawoshBoard from "./assets/Xawoice.svg";
import DatachefBoard from "./assets/Datachef.svg";
import AboutBoard from "./assets/About.svg";

import EmailIcon from "./assets/email.svg";
import ReplayIcon from "./assets/replay.svg";
import LoosterKey from "./assets/turn.svg";
import LoosterOffKey from "./assets/off-key.svg";


import { MoveAudioPlayer } from "./MoveAudioPlayer";

import Looster from "./Looster";
import LoosterButton from "./LoosterButton";
import Project from "./Project";

import keyClickAudio from "./assets/2.mp3"
import projectClickAudio from "./assets/3.mp3"


import "./App.css";

let animObj = null;
let rightClickedInterval = null;
let leftClickedInterval = null;

ReactGA.initialize("UA-171512689-3");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoving: false,
      loop: true,
      movingLeft: false,
      boardRightPos: 0,
      requestMoveDir: 1,
      selectedProject: 0,
      projects: [
        {
          showInfo: false,
          firstPos: -300,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: MightyBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/MightyByte-32d502332e05426a80e50ba42e989e65?pvs=4",
        },
        {
          showInfo: false,
          firstPos: -800,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: SpationBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/Spation-70b506ac8952417eace9c4db1b9aad52",
        },
        {
          showInfo: false,
          firstPos: -1300,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: CoowntBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/Co-ownt-364ff55ebe254455ba5c2e6f1a311aed",
        },
        {
          showInfo: false,
          firstPos: -1800,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: PingonioBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/Pingonio-d3efc07a05ff4d5ea3534a4d7813a887",
        },
        {
          showInfo: false,
          firstPos: -2300,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: XawoshBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/Xawoice-6dea7a968c5d4933aec961538699d59e",
        },
        {
          showInfo: false,
          firstPos: -2800,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: DatachefBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/Datachef-cb76beaba9f040a2a17d501e32a59f78",
        },
        {
          showInfo: false,
          firstPos: -3300,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: ObourBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/Obour-7921344f7e424bb09ad694bb8f62e6d3",
        },
        {
          showInfo: false,
          firstPos: -4000,
          dimension: Math.floor(Math.random() * 100) + 200,
          image: AboutBoard,
          opened: false,
          href: "https://mahdidavoodi.notion.site/Resume-website-055d65dea3e5474da4809cc82278b12f",
        },
      ],
      pos: 0,
      loosters: [
        -1200, -2500, -3800, -5100, -6400, -7700, -9000, -10300, -11600, -12900,
        -14200,
      ],
      loosterIsOn: true,
      sign: 0,
      modalOpened: false,
      showContent: false,
      startMovingRight: false,
      startMovingLeft: false,
      showKeyInfo: false,
      final: -4800,
      firstTime: true,
    };
    this.keyRef = React.createRef();
    this.boardRef = React.createRef();
    this.key1Ref = React.createRef();
  }
  listened = false;
  movingEnded = true;
  moveBoards = (toLeft) => {
    let pos = this.state.pos;
    if (toLeft) {
      pos = pos + 50;
    } else {
      pos = pos - 50;
    }
    this.setState({ pos });
  };
  moveKeyLight = (toLeft) => {
    let key = this.state.key;
    if (toLeft) {
      key = key - 50;
    } else {
      key = key + 50;
    }
    this.setState({ key });
  };
  moveSign = (toLeft) => {
    let sign = this.state.sign;
    if (toLeft) {
      sign = sign + 50;
    } else {
      sign = sign - 50;
    }
    this.setState({ sign });
  };
  moveFunction = (moveRight) => {
    if (moveRight) {
      if (this.state.pos < 0) {
        if (this.state.movingLeft) {
          animObj.play();
          this.movingEnded = false;
          if (!this.listened) {
            this.moveBoards(true);
            // this.moveLoosters(true);
            this.moveSign(true);
            this.moveKeyLight(true);
            this.listened = true;
          }
        } else {
          if (!this.listened) {
            this.setState({
              movingLeft: true,
            });
          }
        }
        this.setState({
          loop: true,
          isMoving: true,
          requestMoveDir: -1,
          firstTime: false,
        });
      }
    } else {
      if (this.state.pos > this.state.final) {
        if (!this.state.movingLeft) {
          animObj.play();
          this.movingEnded = false;
          if (!this.listened) {
            this.moveBoards();
            // this.moveLoosters();
            this.moveSign();
            this.moveKeyLight();
            this.listened = true;
          }
        } else {
          if (!this.listened) {
            this.setState({
              movingLeft: false,
            });
          }
        }
        this.setState({
          loop: true,
          requestMoveDir: 1,
          isMoving: true,
          firstTime: false,
        });
      }
    }
  };
  walkFunction = (event) => {
    if (event.keyCode === 37) {
      this.moveFunction(true);
    } else if (event.keyCode === 39) {
      this.moveFunction(false);
    }
  };
  stopFunction = (event) => {
    if (event.keyCode === 39 || event.keyCode === 37) {
      this.movingEnded = true;
      clearInterval(rightClickedInterval);
      clearInterval(leftClickedInterval);
    }
  };
  stopFunctionForClicked = () => {
    this.movingEnded = true;
  };
  boardClickHandler = (id) => {
    let projects = [...this.state.projects];
    projects[id].opened = true;

    this.setState({ projects });
  };
  openModal = (id) => {
    this.stopFunction({ keyCode: 37 });
    this.setState({ startMovingLeft: false, startMovingRight: false });
    let audioPlayer = new Audio(projectClickAudio);
    audioPlayer.play();
    window.open(this.state.projects[id].href, "_blank");
  };
  renderProjects = (projects) => {
    return projects.map((el, index) => {
      return (
        <Project
          isOn={this.state.loosterIsOn}
          index={index}
          onClick={() => this.openModal(index)}
          image={el.image}
          pos={this.state.pos}
          showInfo={el.showInfo}
          dimension={el.dimension}
          firstPos={el.firstPos}
          key={`project_${index}`}
        />
      );
    });
  };
  renderLoosters = (loosters) => {
    return loosters.map((el, index) => {
      return (
        <Looster
          pos={this.state.pos}
          right={el}
          isOn={this.state.loosterIsOn}
          index={index}
          key={`looster_${index}`}
        />
      );
    });
  };

  handleButtonMoveRight = (number) => {
    if (this.state.startMovingLeft === true) {
      this.stopFunction({ keyCode: 39 });
      this.setState({ startMovingLeft: false, firstTime: false });
    }
    if (this.state.startMovingRight === true) {
      this.stopFunction({ keyCode: 37 });
      this.setState({ startMovingRight: false, firstTime: false });
    } else {
      this.setState({ startMovingRight: true, firstTime: false });
      rightClickedInterval = setInterval(() => {
        this.walkFunction({ keyCode: number });
      }, 50);
    }
  };
  handleButtonMoveLeft = (number) => {
    if (this.state.startMovingRight === true) {
      this.stopFunction({ keyCode: 37 });
      this.setState({ startMovingRight: false });
    }
    if (this.state.startMovingLeft === true) {
      this.stopFunction({ keyCode: 39 });
      this.setState({ startMovingLeft: false });
    } else {
      this.setState({ startMovingLeft: true });
      leftClickedInterval = setInterval(() => {
        this.walkFunction({ keyCode: number });
      }, 50);
    }
  };
  componentDidMount() {
    document.addEventListener("keydown", this.walkFunction, false);
    document.addEventListener("keyup", this.stopFunction, false);
    animObj = lottie.loadAnimation({
      container: this.animBox, // the dom element that will contain the animation
      renderer: "svg",
      loop: this.state.loop,
      autoplay: false,
      animationData: animationData.default, // the path to the animation json
    });
    animObj.setSpeed(2);
    animObj.addEventListener("loopComplete", () => {
      // let projects = [...this.state.projects];
      // for (let item in projects) {
      //   const diff = Math.abs(
      //     (this.refs[item + "_REF"].getBoundingClientRect().right +
      //       this.refs[item + "_REF"].getBoundingClientRect().left) /
      //       2 -
      //       window.innerWidth / 2
      //   );
      //   if (diff < 200) {
      //     projects[item].showInfo = true;
      //   } else {
      //     projects[item].showInfo = false;
      //   }
      // }
      const diff = Math.abs(
        (this.key1Ref.current.getBoundingClientRect().right +
          this.key1Ref.current.getBoundingClientRect().left) /
          2 -
          window.innerWidth / 2
      );
      if (diff < 150) {
        this.setState({ showKeyInfo: true });
      } else {
        this.setState({ showKeyInfo: false });
      }
      // this.setState({ projects });

      if (this.state.requestMoveDir === 1) {
        this.setState({ movingLeft: false });
      } else {
        this.setState({ movingLeft: true });
      }
      if (this.movingEnded) {
        animObj.stop();
      }
      this.listened = false;
    });
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.walkFunction, false);
  }

  render() {
    return (
      <div className="App">
        <div
          onClick={() => {
            window.location.reload();
          }}
          className="replay"
        >
          <img alt="mahdidavoodi-replay-icon" src={ReplayIcon} />
        </div>
        <a href="mailto:mahdidavoodi7@gmail.com">
          <div
            className="email"
          >
            <img alt="mahdidavoodi-email-icon" src={EmailIcon} />
          </div>
        </a>
        <LoosterButton
          onClick={() => {
            let audioPlayer = new Audio(keyClickAudio);
            audioPlayer.play();
            this.setState((prev) => ({ loosterIsOn: !prev.loosterIsOn }));
          }}
          isOn={this.state.loosterIsOn}
        />
        <MoveAudioPlayer isMoving={!this.movingEnded} />
        <div
          className="overlay"
          style={{
            opacity: this.state.loosterIsOn ? 0 : 0.5,
          }}
        ></div>
        <div className="obj-container">
          <div
            className={this.state.movingLeft ? "_360deg" : "_0deg"}
            ref={(ref) => (this.animBox = ref)}
          ></div>
        </div>
        <section className="App-header">
          {this.renderLoosters(this.state.loosters)}
          {this.renderProjects(this.state.projects)}

          {/* <img
            style={{ transform: `translateX(${this.state.sign}px)` }}
            src={Arrow}
            className="sign"
          /> */}
          <div
            ref={this.key1Ref}
            className="key"
            style={{ transform: `translateX(${this.state.sign}px)` }}
          >
            {/* <div style={{display: this.state.showKeyInfo ? "block" : "none"}}>
                <span>Click!</span>
                <img src={moreInfo2Icon} />
              </div> */}
            <img
              alt="dark-light-key"
              onClick={() => {
                let audioPlayer = new Audio(keyClickAudio);
                audioPlayer.play();
                this.setState((prev) => ({ loosterIsOn: !prev.loosterIsOn }));
              }}
              src={this.state.loosterIsOn ? LoosterKey : LoosterOffKey}
            />
          </div>
          <div className="info" style={{ transform: `translateX(${this.state.pos + 240}px)` }}>
            Hi, My name is Mahdi Davoodi.<br/>
            <div className="info-2">
              I am a full-stack and react-native developer, and this website is here to show you my projects.
              You can start exploring by<div> pressing <span>arrow keys</span> on your keyboard or </div> pressing the <span>"click to start!" button</span>.
            </div>
            <div className="info-3">
              You can be in touch with me with this email address:
            </div>
            <div className="info-4">
            mahdidavoodi7@gmail.com
            </div>
          </div>
        </section>
        <div
          className="bottom"
          style={{
            backgroundColor: this.state.loosterIsOn ? "gray" : "#2f2f2f",
          }}
        ></div>
        <div className="arrow-key-container">
          {/* <div
            onClick={() => this.handleButtonMoveLeft(37)}
            className={`icon-container icon-container-left ${
              !this.movingEnded && this.state.movingLeft ? "active" : ""
            }`}
          >
            <img
              alt="left-arrow"
              src={
                !this.movingEnded && this.state.movingLeft
                  ? PauseIcon
                  : LeftIcon
              }
            />
          </div> */}
          <div
            className={`icon-container ${
              !this.movingEnded && !this.state.movingLeft ? "active" : ""
            }`}
            onClick={() => this.handleButtonMoveRight(39)}
          >
            {this.state.firstTime && <span>Click to start!</span>}
            <img
              className={!this.state.firstTime ? "showw" : ""}
              alt="left-arrow"
              src={
                !this.movingEnded && !this.state.movingLeft
                  ? PauseIcon
                  : RightIcon
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
