import React from "react";
import { Light } from "./light.js";

export const LoosterButton = ({ onClick, isOn }) => {
  return (
    <div
      onClick={onClick}
      className="light"
    >
      <Light isOn={isOn} />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  if(prevProps.isOn !== nextProps.isOn) {
    return false
  }
  return true
}

export default React.memo(LoosterButton, areEqual);