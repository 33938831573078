import React from "react";

export const Light = ({ isOn = false }) => {
  if (!isOn) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 100 125"
        x="0px"
        y="0px"
        width="100%"
      >
        <path d="M57.05,76.29H43A3.93,3.93,0,0,1,39,72.37V67.32a6.5,6.5,0,0,0-2.17-4.77,19.59,19.59,0,0,1-4.8-22.43h0A19.6,19.6,0,1,1,63.19,62.5,6.52,6.52,0,0,0,61,67.32v5.05A3.93,3.93,0,0,1,57.05,76.29ZM34.07,41a17.44,17.44,0,0,0,4.27,19.91,8.69,8.69,0,0,1,2.9,6.4v5.05A1.72,1.72,0,0,0,43,74.09h14.1a1.72,1.72,0,0,0,1.71-1.72V67.32a8.82,8.82,0,0,1,3-6.45,17.34,17.34,0,0,0,5.64-14.11A17.4,17.4,0,0,0,34.07,41Z" />
        <rect x="41.19" y="78.78" width="17.61" height="2.2" />
        <rect x="41.19" y="83.35" width="17.61" height="2.2" />
        <rect x="41.19" y="87.78" width="17.61" height="2.2" />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 100 125"
      x="0px"
      y="0px"
      width="100%"
    >
      <path  d="M57.05,76.29H43A3.93,3.93,0,0,1,39,72.37V67.32a6.5,6.5,0,0,0-2.17-4.77,19.59,19.59,0,0,1-4.8-22.43h0A19.6,19.6,0,1,1,63.19,62.5,6.52,6.52,0,0,0,61,67.32v5.05A3.93,3.93,0,0,1,57.05,76.29ZM34.07,41a17.44,17.44,0,0,0,4.27,19.91,8.69,8.69,0,0,1,2.9,6.4v5.05A1.72,1.72,0,0,0,43,74.09h14.1a1.72,1.72,0,0,0,1.71-1.72V67.32a8.82,8.82,0,0,1,3-6.45,17.34,17.34,0,0,0,5.64-14.11A17.4,17.4,0,0,0,34.07,41Z" />
      <rect  x="48.9" y="10.02" width="2.2" height="10.77" />
      <rect
        x="32.59"
        y="14.38"
        width="2.21"
        height="10.77"
        transform="translate(-5.37 19.5) rotate(-30)"
        
      />
      <rect
        x="20.65"
        y="26.32"
        width="2.21"
        height="10.77"
        transform="translate(-16.58 34.69) rotate(-60)"
        
      />
      <rect  x="12" y="46.91" width="10.77" height="2.2" />
      <rect
        x="16.37"
        y="63.22"
        width="10.77"
        height="2.21"
        transform="translate(-29.24 19.49) rotate(-29.99)"
        
      />
      <rect
        x="77.14"
        y="58.94"
        width="2.21"
        height="10.77"
        transform="translate(-16.58 99.93) rotate(-60)"
        
      />
      <rect x="77.23" y="46.91" width="10.77" height="2.2"  />
      <rect
        x="72.86"
        y="30.6"
        width="10.77"
        height="2.21"
        transform="translate(-5.37 43.36) rotate(-29.99)"
        
      />
      <rect
        x="60.93"
        y="18.67"
        width="10.77"
        height="2.21"
        transform="translate(16.04 67.31) rotate(-60.01)"
        
      />
      <rect x="41.19" y="78.78" width="17.61" height="2.2" />
      <rect x="41.19" y="83.35" width="17.61" height="2.2" />
      <rect x="41.19" y="87.78" width="17.61" height="2.2" />
    </svg>
  );
};
